export const useFetchMeta = async () => {
	return
	const nuxtApp = useNuxtApp(); // Must be called inside the Nuxt lifecycle context
  
	await nuxtApp.runWithContext(async () => {
	  const runtimeConfig = useRuntimeConfig();
	  const { $country } = nuxtApp; // You can get this from nuxtApp
	  const metaObj = ref({});
	  const title = ref('Some Page'); // Default title
	  const route = useRoute();
	  const router = useRouter();
  
	  let path = route.path;
	  
	  // Adjust path for home and specific country paths
	  if (path === '/') {
		path = '/home';
	  }
	  if (path === '/in') {
		path = '/in/home';
	  }
  
	  try {
		// Use the `useFetch` composable to fetch the meta data
		const { data, error } = await useFetch(useCountryPath('/config/meta'), {
		  params: { path },
		});
  
		if (error.value) {
		  console.error('Error fetching meta data:', error.value);
		  return;
		}
  
		const obj = data.value.data;
		const canonicalPath = `${runtimeConfig.public.domain}${obj.canonical_url}`;
		let finalUrl = canonicalPath;
  
		// Adjust URL based on country
		if ($country.location === 'US') {
		  finalUrl = canonicalPath.replace(runtimeConfig.public.domain, runtimeConfig.public.domain + '/in');
		} else if ($country.location === 'IN') {
		  finalUrl = canonicalPath.replace(runtimeConfig.public.domain + '/in', runtimeConfig.public.domain);
		}
  
		// Set meta data object
		title.value = obj.meta_title;
		metaObj.value = {
		  title: obj.meta_title,
		  meta: [
			{ name: 'description', content: obj.meta_description },
			{ name: 'keywords', content: obj.meta_keyword },
			{ property: 'og:title', content: obj.meta_title },
			{ property: 'og:type', content: 'website' },
			{ property: 'og:url', content: canonicalPath },
			{ property: 'og:description', content: obj.meta_description },
			{ property: 'og:site_name', content: 'Friendly Diamonds' },
			{ property: 'og:image', content: '' },
			{ property: 'twitter:title', content: obj.meta_title },
		  ],
		  link: [
			{ rel: 'canonical', href: canonicalPath },
			{ rel: 'alternate', hreflang: $country.language, href: finalUrl },
			{ rel: 'alternate', hreflang: `en-${$country.location}`, href: canonicalPath },
		  ],
		};
  
		// Apply meta tags using useHead
		useHead(metaObj.value);
	  } catch (err) {
		console.error('Error fetching meta data:', err);
	  }
	});
  };
  